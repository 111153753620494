<template>
  <div class="greygoose-product__items">
    <swiper ref="swiperRef" :options="swiperOption" >
      <swiper-slide v-for="item in items" :key="item">
        <div class="greygoose-product__item">
          <img :src="`/greygoose/${item.img}`" alt="">
          <p>{{ item.name }}</p>
          <span>{{ item.litr }}</span>
        </div>
      </swiper-slide>
    </swiper>
    <div class="arr prev mob" @click="prevSlide()">
      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
        <circle cx="22" cy="22" r="22" transform="rotate(-180 22 22)" fill="#003053"/>
        <path d="M24.5377 30.4615L16.0762 21.9999L24.5377 13.5384" stroke="white" stroke-width="2" stroke-linecap="square"/>
      </svg>
    </div>
    <div class="arr next mob" @click="nextSlide()">
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22" cy="22" r="22" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 0 44)" fill="#003053"/>
        <path d="M19.4623 30.4615L27.9238 21.9999L19.4623 13.5384" stroke="white" stroke-width="2" stroke-linecap="square"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductSlider",
  // components: {
  //   ArticlesBottomList: () =>
  //     import("@/components/articles/ArticlesBottomList.vue"),
  //   ArticlesTopList: () => import("@/components/articles/ArticlesTopList.vue"),
  // },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      swiperOption: {
        // spaceBetween: 50,
        lazy: true,
        slideTo: 1,
        loop: true,
        // spaceBetween: ,
        // initialSlide: 1,
        // centeredSlides: false,
        // responsive: true,
        breakpoints: {
          600: {
            loop: true,
            slidesPerView: 1,
          },
          800: {
            loop: true,
            slidesPerView: 3,
          },
          1000: {
            loop: false,
            slidesPerView: 3,
          },
        },
      },
    };
  },
  
  computed: {
    swiper() {
      return this.$refs?.swiperRef
    },
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev()
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext()
    },
  },
};
</script>

<style lang="scss" scoped></style>
